var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-card',[_c('v-data-table',{staticClass:"pt-3",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.filteredItems,"search":_vm.search,"sort-by":['order_date', 'order_number'],"show-select":_vm.bulkActions,"item-key":"order_id","dense":"","items-per-page":70},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"3","sm":"3"}},[_c('v-select',{attrs:{"label":"Order Status","items":[
                    { text: 'Open', value: 'Confirmed' },
                    { text: 'Completed', value: 'Completed' } ],"clearable":"","append-icon":"mdi-menu-down","dense":"","outlined":""},model:{value:(_vm.order_status),callback:function ($$v) {_vm.order_status=$$v},expression:"order_status"}})],1),_c('v-col',{attrs:{"md":"3","sm":"3"}},[_c('v-text-field',{attrs:{"label":"Search","clearable":"","append-icon":"mdi-magnify","dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"md":"3","sm":"3"}},[_c('InvoicesBulkActionsBtn',{attrs:{"bulkActions":_vm.bulkActions,"bulkMenuItems":_vm.bulkMenuItems},on:{"changeBulkActions":function($event){return _vm.changeBulkActions($event)},"changeBulkInvoices":function($event){return _vm.changeBulkInvoices($event)}}})],1)],1)],1)]},proxy:true},{key:"item.order_number",fn:function(ref){
                  var item = ref.item;
return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
              name: 'view-order',
              params: { order_id: item.order_id },
            },"target":"blank"}},[_vm._v(_vm._s(item.order_number))])]}},{key:"item.customer",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer.customer_name)+" ")]}},{key:"item.order_value",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatAsCurrency("R", item.order_value))+" ")]}},{key:"item.order_id",fn:function(props){return [_c('v-btn',{attrs:{"text":"","color":"#3dcb9a","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.$set(_vm.newInvoiceDialog, props.item.order_id, true)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" Create Invoice")],1),_c('v-dialog',{key:props.item.newInvoiceDialog,attrs:{"scrollable":"","max-width":"950"},model:{value:(_vm.newInvoiceDialog[props.item.order_id]),callback:function ($$v) {_vm.$set(_vm.newInvoiceDialog, props.item.order_id, $$v)},expression:"newInvoiceDialog[props.item.order_id]"}},[_c('NewInvoiceModal',{key:_vm.componentKey,attrs:{"value":props.item}})],1)]}}]),model:{value:(_vm.selected_orders),callback:function ($$v) {_vm.selected_orders=$$v},expression:"selected_orders"}}),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.bulkInvoiceModal),callback:function ($$v) {_vm.bulkInvoiceModal=$$v},expression:"bulkInvoiceModal"}},[(_vm.bulkInvoiceModal)?_c('InvoiceBulkModal',{attrs:{"selected_orders":_vm.selected_orders},on:{"closeBulkInvoiceModal":function($event){return _vm.closeBulkInvoiceModal($event)}}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }