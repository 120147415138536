<template>
  <v-menu offset-y open-on-hover :disabled="!bulkActions">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="float-right"
        outlined
        @click="passBulkActions"
        v-bind="attrs"
        v-on="on"
        >Bulk Actions <v-icon right small>mdi-menu-down</v-icon></v-btn
      >
    </template>
    <v-list>
      <v-list-item v-for="item in bulkItems" :key="item.name">
        <v-list-item-title @click.stop="item.action" class="change-cursor"
          ><v-icon left small>{{ item.icon }}</v-icon
          >{{ item.name }}</v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  name: "InvoicesBulkActionsBtn",
  props: ["bulkActions", "bulkInvoiceModal", "bulkMenuItems"],
  data() {
    return {
      bulkItems: [
        {
          name: "Close Menu",
          icon: "mdi-close",
          action: () => this.$emit("changeBulkActions"),
        },
      ],
    };
  },
  created() {
    this.bulkItems = [...this.bulkMenuItems, ...this.bulkItems];
  },
  methods: {
    passBulkActions() {
      this.$emit("changeBulkActions");
    },
  },
};
</script>
<style scoped>
.change-cursor:hover {
  cursor: pointer;
}
</style>