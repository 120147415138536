<template>
  <v-row>
    <v-col>
      <v-card>
        <v-data-table
          v-model="selectedInvoices"
          :headers="headers"
          :items="
            invoices.map((obj) => ({
              ...obj,
              customer_name: obj.customer.customer_name,
            }))
          "
          :search="search"
          :loading="loading"
          :sort-by="['invoice_number', 'invoice_issue_date']"
          dense
          :items-per-page="50"
          class="pt-3"
          :show-select="bulkActions"
          item-key="invoice_id"
        >
          <!--Filters-->
          <template v-slot:top>
            <InvoiceTableFilters
              :search="search"
              @changeSearch="search = $event"
              :bulkActions="bulkActions"
              :bulkMenuItems="bulkMenuItems"
              @changeBulkActions="bulkActions = !bulkActions"
            />
          </template>
          <!--Link to sales order-->
          <template v-slot:item.sales_order_ref="{ item }">
            <a @click="pushRouteData(item)">{{ item.sales_order_ref }}</a>
          </template>
          <!--Invoice Total-->
          <template v-slot:item.invoice_total="{ item }">
            {{ formatAsCurrency("R", item.invoice_total) }}
          </template>
          <!--Invoice Amount Paid-->
          <template v-slot:item.invoice_amount_paid="{ item }">
            {{ formatAsCurrency("R", item.invoice_amount_paid) }}
          </template>
          <!--Invoice Amount Due-->
          <template v-slot:item.invoice_amount_due="{ item }">
            <span style="color: #f2453d">{{
              formatAsCurrency("R", item.invoice_amount_due)
            }}</span>
          </template>
          <!--Invoice Action Menu-->
          <template v-slot:item.invoice_id="props">
            <!--Menu-->
            <v-menu top left>
              <template v-slot:activator="{ on, attrs }">
                <!--Menu Button-->
                <v-btn icon color="black" right v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <!--Menu List-->
              <v-list>
                <v-list-item
                  v-for="(item, index) in menu_items"
                  :key="index"
                  :disabled="!!item.disabled && item.disabled(props.item)"
                >
                  <v-list-item-title
                    @click.stop="item.action(props.item)"
                    class="change-cursor"
                    ><v-icon small left>{{ item.icon }}</v-icon
                    >{{ item.title }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
            <!--Paymnet Allocation Modal-->
            <InvoicePaymentAllocationModal
              :paymentDialog="paymentDialog"
              :invoice="props.item"
            />
            <!--Paymnet Preview Modal-->
            <InvoicePaymentPreviewModal
              :ref="props.item.invoice_id"
              :paymentPreviewDialog="paymentPreviewDialog"
              :value="props.item"
              :type="'invoice'"
              @passAllocateMorePayments="$set(paymentDialog, $event, true)"
            />
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import db from "../../../../components/firebaseInit";
import { formatAsCurrency } from "../../../../composables/external";
import {
  generateInvoicePDF,
  mergeInvoicePDFs,
} from "../../data/external_InvoicePDFTemplate";
import mixin_CompanyProfile from "../../../../globalActions/mixin_CompanyProfile";
export default {
  name: "UnpaidInvoicesTable",
  mixins: [mixin_CompanyProfile],
  components: {
    InvoicePaymentAllocationModal: () =>
      import(
        /* webpackChunkName: "accounting" */ "../../components/Modals/InvoicePaymentAllocationModal.vue"
      ),
    InvoicePaymentPreviewModal: () =>
      import(
        /* webpackChunkName: "accounting" */ "../../components/Modals/InvoicePaymentPreviewModal.vue"
      ),
    InvoiceTableFilters: () => import("./InvoiceTableFilters.vue"),
  },
  data() {
    return {
      selectedInvoices: [],
      invoices: [],
      search: "",
      loading: false,
      paymentDialog: {},
      paymentPreviewDialog: {},
      VATDate: null,
      isVattable: null,
      bulkActions: false,
      bulkMenuItems: [
        {
          name: "Merge & Export as PDF",
          icon: "mdi-file-download-outline",
          action: () => this.mergeInvoicePDFs(this.selectedInvoices),
        },
      ],
      headers: [
        {
          text: "Invoice #",
          value: "invoice_number",
          align: "start",
        },
        { text: "Sales Order #", value: "sales_order_ref" },
        { text: "Customer", value: "customer_name" },
        { text: "Date Issued", value: "invoice_issue_date" },
        { text: "Date Due", value: "invoice_due_date" },
        { text: "Total", value: "invoice_total", align: "right" },
        { text: "Paid", value: "invoice_amount_paid", align: "right" },
        { text: "Due", value: "invoice_amount_due", align: "right" },
        { text: "Actions", value: "invoice_id", align: "right" },
      ],
      menu_items: [
        {
          title: "Download PDF",
          icon: "mdi-download",
          action: (invoice) => this.generateInvoicePDF(invoice),
        },
        {
          title: "Allocate Payment",
          icon: "mdi-link",
          action: (invoice) =>
            this.$set(this.paymentDialog, invoice.invoice_id, true),
        },
        {
          title: "View Payments",
          icon: "mdi-eye",
          disabled: (invoice) => this.returnPaymentsViewDisabled(invoice),
          action: (invoice) => {
            this.$set(this.paymentPreviewDialog, invoice.invoice_id, true);
            //  Used to retreive payments from database and load them in
            //  The modal child component
            this.$refs[invoice.invoice_id].getCustomerPayments();
          },
        },
        {
          title: "Delete Invoice",
          icon: "mdi-delete",
          disabled: (invoice) => this.returnDeleteDisabled(invoice),
          action: (invoice) => this.deleteInvoice(invoice),
        },
      ],
    };
  },
  created() {
    this.getInvoices();
    this.getVATdata();
  },
  methods: {
    formatAsCurrency,
    generateInvoicePDF,
    mergeInvoicePDFs,
    getVATdata() {
      db.collection("company_profile")
        .doc("NVmvKiV1F7os4LCRljSC")
        .get()
        .then((snapshot) => {
          this.isVattable = snapshot.data().company_is_vattable;
          this.VATDate = snapshot.data().VAT_start_date;
        });
    },
    getInvoices() {
      this.loading = true;
      const invoiceRef = db
        .collection("invoices")
        .where("invoice_amount_due", "!=", 0);
      invoiceRef.onSnapshot((querySnapshot) => {
        this.invoices = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          this.invoices.push(data);
        });
        this.loading = false;
      });
    },
    deleteInvoice() {
      alert("Deleted");
    },
    returnDeleteDisabled(invoice) {
      return invoice.invoice_amount_paid !== 0 ? true : false;
    },
    returnPaymentsViewDisabled(invoice) {
      return invoice.customer_payments && invoice.customer_payments.length > 0
        ? false
        : true;
    },
    pushRouteData(item) {
      const routeData = this.$router.resolve({
        path: `view-order/${item.sales_order_id}`,
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
<style scoped>
.change-cursor:hover {
  cursor: pointer;
}
</style>