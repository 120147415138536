<template>
  <v-sheet elevation="2" class="pa-10" height="100%" overflow="scroll">
    <v-form v-model="valid" ref="form" @submit.prevent="createNewInvoice">
      <v-container>
        <!--Header-->
        <v-row class="mb-8">
          <v-col md="12" sm="12">
            <v-spacer></v-spacer>
            <!--Save-->
            <v-btn
              :loading="loading"
              dark
              elevation="0"
              class="float-right"
              color="#3dcb9a"
              @click="
                createNewInvoice();
                closeDialog();
              "
              ><v-icon left>mdi-check</v-icon>Approve & Save</v-btn
            >
            <!--Actions-->
            <InvoiceActionBtn :order_id="value.order_id" />
            <h2>Create New Invoice</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              :value="invoice_number"
              label="Invoice #"
              outlined
              dense
              color="#3d2cdd"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              :value="value.order_number"
              label="Sales Order Ref #"
              readonly
              outlined
              dense
              color="#3d2cdd"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <!--Date Issued-->
            <InvoiceDatePicker
              date_label="Date Issued"
              :default_date="value.order_date"
              v-model="invoice_issue_date"
              @changeDate="
                changeIssueDate($event);
                setVATRate();
              "
            />
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <!--Date Due-->
            <InvoiceDatePicker
              date_label="Due Date"
              :default_date="new Date().toISOString().substr(0, 10)"
              v-model="invoice_due_date"
              @changeDate="changeDueDate($event)"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" sm="6">
            <v-text-field
              :value="value.customer.customer_name"
              label="Customer Name"
              readonly
              outlined
              dense
              color="#3d2cdd"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!--Line Items-->
        <div class="small-font">
          <v-row class="mt-3" style="font-weight: bold">
            <v-col md="6" sm="6"><p>Item</p></v-col>
            <v-col md="2" sm="2" style="text-align: center"
              ><p>Quantity</p></v-col
            >
            <v-col md="2" sm="2" style="text-align: right">
              <p>Price</p>
            </v-col>
            <v-col md="2" sm="2" style="text-align: right"><p>Amount</p></v-col>
          </v-row>
          <v-row v-for="item in value.line_items" :key="item.id">
            <v-col md="6" sm="6">{{ item.product_name }}</v-col>
            <v-col md="2" sm="2" style="text-align: center">{{
              item.item_qty
            }}</v-col>
            <v-col md="2" sm="2" style="text-align: right">{{
              formatAsCurrency("R", item.product_price)
            }}</v-col>
            <v-col md="2" sm="2" style="text-align: right">{{
              formatAsCurrency("R", item.line_item_amount)
            }}</v-col>
          </v-row>
          <v-row class="my-3">
            <v-col>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <!--Sub totals-->
          <div v-for="item in subTotals" :key="item.name">
            <v-row v-if="item.value">
              <v-col lg="5" md="5" sm="5" offset="7">
                <span style="font-weight: bold">{{ item.name }}:</span>
                <span class="float-right">{{
                  formatAsCurrency("R", item.value)
                }}</span>
              </v-col>
            </v-row>
          </div>
          <!--VAT totals-->
          <div
            v-show="
              this.company_is_vattable === true &&
              this.invoice_issue_date >= this.VATDate
            "
          >
            <v-row>
              <v-col lg="5" md="5" sm="5" offset="7">
                <span style="font-weight: bold">Total (Ex VAT)</span>
                <span class="float-right">{{
                  formatAsCurrency("R", calculateTotalExVat)
                }}</span>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col lg="5" md="5" sm="5" offset="7">
                <span style="font-weight: bold">VAT Amount</span>
                <span class="float-right">{{
                  formatAsCurrency("R", calculateVATamount)
                }}</span>
              </v-col>
            </v-row>
          </div>
          <!---->
          <v-row class="my-3">
            <v-col lg="5" md="5" sm="5" offset="7">
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <!--totals-->
          <v-row style="font-size: 1.5rem">
            <v-col lg="5" md="5" sm="5" offset="7">
              <span style="font-weight: bold">Total:</span>
              <span class="float-right">{{
                formatAsCurrency("R", value.order_value)
              }}</span>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-form>
  </v-sheet>
</template>
<script>
import db from "../../../../components/firebaseInit";
import firebase from "firebase";
import mixin_CompanyProfile from "../../../../globalActions/mixin_CompanyProfile";
import { formatAsCurrency } from "../../../../composables/external";
import {
  getJournalEntryCount,
  formatJournalEntryNumber,
  incrementJournalEntryCount,
} from "../../data/external_invoices";
export default {
  name: "NewInvoiceModal",
  props: ["value"],
  components: {
    InvoiceDatePicker: () => import("../Inputs/InvoiceDatePicker.vue"),
    InvoiceActionBtn: () => import("../Buttons/InvoiceActionsBtn.vue"),
  },
  mixins: [mixin_CompanyProfile],
  data() {
    return {
      loading: false,
      invoice_id: null,
      invoice_number: null,
      sales_order_ref: null,
      quote_ref: null,
      invoice_issue_date: null,
      invoice_due_date: null,
      customer: null,
      line_items: null,
      invoice_total: null,
      order_subtotal: null,
      order_discount: null,
      order_discounted_subtotal: null,
      order_delivery_fee: null,
      order_total_ex_VAT: null,
      invoice_amount_paid: null,
      invoice_amount_due: null,
      customer_payments: null,
      entry_id: null,
      journal_entry_count: null,
      VAT_rate: null,
      isVattable: null,
      VATDate: null,

      subTotals: [
        { name: "Subtotal", value: this.value.order_subtotal },
        { name: "Discount", value: this.value.order_discount },
        {
          name: "Discounted Subtotal",
          value: this.value.order_discounted_subtotal,
        },
        { name: "Delivery Fee", value: this.value.order_delivery_fee },
      ],
    };
  },
  computed: {
    calculateTotalExVat() {
      return (
        (this.value.order_discounted_subtotal + this.value.order_delivery_fee) /
        (1 + this.VAT_rate)
      );
    },
    calculateVATamount() {
      return this.value.order_value - this.calculateTotalExVat;
    },
  },
  created() {
    this.getInvoiceCount();
    this.getJournalEntryCount();
    // Get company VAT status from Company Profile
    this.setVATRate();
  },
  mounted() {},

  methods: {
    formatAsCurrency,
    getJournalEntryCount,
    formatJournalEntryNumber,
    incrementJournalEntryCount,
    getInvoiceCount() {
      const countRef = db.doc("order_number_counter/VVr0gV8WMDdzHNwkKQSe");
      let count;
      countRef.onSnapshot((doc) => {
        count = doc.data().invoice_count;
        // Format INV number
        this.invoice_number =
          count < 1000
            ? `INV-${String("00000" + count).slice(-4)}`
            : `INV-${count}`;
      });
    },
    //
    setVATRate() {
      db.collection("company_profile")
        .doc("NVmvKiV1F7os4LCRljSC")
        .get()
        .then((snapshot) => {
          const isVattable = snapshot.data().company_is_vattable;
          this.isVattable = isVattable;
          const VATDate = snapshot.data().VAT_start_date;
          this.VATDate = VATDate;
          //  If company is Vattable && order is after the VAT start date, set rate to 15%
          this.VAT_rate =
            isVattable && VATDate <= this.invoice_issue_date ? 0.15 : 0;
        });
    },
    //
    createNewInvoice() {
      this.loading = true;
      const batch = db.batch();
      const invoiceRef = db.collection("invoices").doc();
      const entryRef = db.collection("general_journal_entries").doc();
      //    Save invoice to database
      const saveInvoiceToDB = () => {
        batch.set(invoiceRef, {
          invoice_id: invoiceRef.id,
          invoice_number: this.invoice_number,
          invoice_issue_date: this.invoice_issue_date,
          invoice_due_date: this.invoice_due_date,
          sales_order_ref: this.value.order_number,
          sales_order_id: this.value.order_id,
          quote_ref: this.value.quote_ref ? this.value.quote_ref : null,
          customer: this.value.customer,
          line_items: this.value.line_items,
          order_subtotal: this.value.order_subtotal,
          order_discount: this.value.order_discount,
          discountRate: this.value.discountRate,
          order_discounted_subtotal: this.value.order_discounted_subtotal,
          order_delivery_fee: this.value.order_delivery_fee,
          invoice_total: this.value.order_value,
          // Some older sales orders don't have "paid" and "due" fields
          // This ternary offers a fallback
          invoice_amount_paid: this.value.order_amount_paid,
          invoice_amount_due: this.value.order_amount_due,
          // Links allocated payments && GJE id to the invoice
          // (additional steps further down in this code)
          customer_payments: this.value.customer_payments
            ? this.value.customer_payments
            : [],
          entry_id: entryRef.id,
        });
      };
      //    Add invoice ID to sales order
      const updateSalesOrderInfo = () => {
        const salesOrderRef = db.doc(`sales_orders/${this.value.order_id}`);
        batch.update(salesOrderRef, {
          invoice_id: invoiceRef.id,
          invoice_number: this.invoice_number,
        });
      };
      // ACCOUNTING LOGIC
      //  Create Journal entry to record changes in respective ledgers
      //  Calculate Down Payments Contra
      const calcContraAmount = () => {
        const paymentRef = this.value.customer_payments;
        const contraAmount = paymentRef
          ? paymentRef.reduce((total, el) => total + el.payment_amount, 0)
          : 0;
        return contraAmount;
      };
      const createJournalEntry = () => {
        if (!this.value.customer_payments) return;
        //  Revenue
        const revenueObj = {
          entry_amount: this.value.order_value,
          line_amount: this.value.order_value,
          ledger_account: {
            account_id: "qQGkDkuO9msttN4IFB1z",
            account_name: "Revenue",
          },
        };
        //  Accounts Receivable
        const receivablesObj = {
          entry_amount: this.value.order_amount_due,
          line_amount: this.value.order_amount_due,
          ledger_account: {
            account_id: "Zb7fNd7tfFmOntoUcUUt",
            account_name: "Accounts Receivable",
          },
        };
        //   Down Payments Contra
        const downPaymentsContraObj = {
          entry_amount: calcContraAmount(),
          line_amount: calcContraAmount(),
          ledger_account: {
            account_id: "BHlsHkQ5SXkPDSSK5Vh2",
            account_name: "Customer Down Payments Contra",
          },
        };
        // VAT Output
        const VATOutputObj = {
          entry_amount: this.calculateVATamount,
          line_amount: this.calculateVATamount,
          ledger_account: {
            account_id: "tN26TJD9AIdH7a6CZrAr",
            account_name: "VAT Output",
          },
        };

        const lineItems = [revenueObj];
        if (this.value.order_amount_due !== 0) lineItems.push(receivablesObj);
        if (this.value.customer_payments) lineItems.push(downPaymentsContraObj);
        if (
          this.company_is_vattable === true &&
          this.invoice_issue_date >= this.VATDate
        )
          lineItems.push(VATOutputObj);
        // GJE
        const GJEObj = {
          journal_entry_id: entryRef.id,
          journal_entry_date: this.value.order_date,
          journal_entry_number: this.formatJournalEntryNumber(),
          closed_off: false,
          line_items: lineItems,
          reference_number: this.invoice_number,
          invoice_id: invoiceRef.id,
          invoice_number: this.invoice_number,
        };

        batch.set(entryRef, { ...GJEObj });
      };
      // ACCOUNTING LOGIC
      const updateLedgerAccounts = () => {
        //    Increase revenue
        const increaseRevenue = () => {
          const revenueRef = db.doc(
            `accounting_totals/${this.invoice_issue_date.slice(
              0,
              7
            )}/ledger_accounts/qQGkDkuO9msttN4IFB1z`
          );
          batch.update(revenueRef, {
            monthly_total: firebase.firestore.FieldValue.increment(
              Math.abs(this.value.order_value).toFixed(4)
            ),
          });
        };
        //    Increase accounts receivable
        const increaseReceivables = () => {
          const receivablesRef = db.doc(
            `accounting_totals/${this.invoice_issue_date.slice(
              0,
              7
            )}/ledger_accounts/Zb7fNd7tfFmOntoUcUUt`
          );
          batch.update(receivablesRef, {
            monthly_total: firebase.firestore.FieldValue.increment(
              Math.abs(
                this.value.order_amount_due
                  ? this.value.order_amount_due
                  : this.value.order_value
              ).toFixed(4)
            ),
          });
        };
        // Increase VAT output
        const increaseVATOutput = () => {
          const vatRef = db.doc(
            `accounting_totals/${this.invoice_issue_date.slice(
              0,
              7
            )}/ledger_accounts/tN26TJD9AIdH7a6CZrAr`
          );
          batch.update(vatRef, {
            monthly_total: firebase.firestore.FieldValue.increment(
              Math.abs(this.calculateVATamount).toFixed(4)
            ),
          });
        };
        // Increase downpayments contra
        const increaseDownPaymentsContra = () => {
          const contraRef = db.doc(
            `accounting_totals/${this.invoice_issue_date.slice(
              0,
              7
            )}/ledger_accounts/BHlsHkQ5SXkPDSSK5Vh2`
          );
          batch.update(contraRef, {
            monthly_total: firebase.firestore.FieldValue.increment(
              Math.abs(calcContraAmount()).toFixed(4)
            ),
          });
        };
        increaseRevenue();
        if (this.value.order_amount_due !== 0) increaseReceivables();
        if (calcContraAmount() !== 0) increaseDownPaymentsContra();
        // Test if invoice date is greater than VAT start date
        if (
          this.company_is_vattable === true &&
          this.invoice_issue_date >= this.VATDate
        )
          increaseVATOutput();
      };
      //  Increment invoice_count
      const incrementInvoiceCount = () => {
        const countRef = db.doc("order_number_counter/VVr0gV8WMDdzHNwkKQSe");
        batch.update(countRef, {
          invoice_count: firebase.firestore.FieldValue.increment(1),
        });
      };
      saveInvoiceToDB();
      updateSalesOrderInfo();
      createJournalEntry();
      updateLedgerAccounts();
      incrementInvoiceCount();
      this.journal_entry_count++;
      this.incrementJournalEntryCount();

      batch
        .commit()
        .then(() => (this.loading = false))
        .catch((error) => error);
    },
    changeIssueDate(value) {
      return (this.invoice_issue_date = value);
    },
    changeDueDate(value) {
      return (this.invoice_due_date = value);
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>
<style scoped>
.small-font {
  font-size: 0.95rem;
}
</style>
