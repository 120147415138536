<template>
  <v-card class="pa-5">
    <v-card-title class="text-h5">
      Bulk Create Invoices {{ count }}</v-card-title
    >

    <v-card-text>
      <p>
        You are about to create invoices for all the sales orders in your
        selection.
      </p>
      <p>
        <em>Note:</em> The "Date of Issue" and "Due Date" will be automatically
        set to the sales order date.
      </p>
      <v-divider class="mb-3"></v-divider>
      <h4 style="font-weight: bold" class="mb-3">Orders Included:</h4>
      <p>
        <span
          v-for="item in this.selected_orders"
          :key="item.order_id"
          class="mr-2"
          >{{ `${item.order_number}, ` }}</span
        >
      </p>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn color="black" text @click="closeBulkInvoiceModal"> Cancel </v-btn>

      <v-btn
        color="#3dcb9a"
        style="color: white"
        elevation="0"
        @click="createBulkInvoices()"
        :disabled="this.selected_orders.length === 0"
        :loading="loading"
      >
        Create Invoices ({{ selected_orders.length }})
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import db from "../../../../components/firebaseInit";
import {
  createBulkInvoices,
  getInvoiceCount,
  formatJournalEntryNumber,
} from "../../data/external_invoices";
export default {
  name: "InvoiceBulkModal",
  props: ["selected_orders"],
  data() {
    return {
      loading: false,
      count: null,
      journal_entry_count: null,
    };
  },
  created() {
    this.getInvoiceCount();
    this.getJournalEntryCount();
  },
  methods: {
    createBulkInvoices,
    getInvoiceCount,
    formatJournalEntryNumber,
    closeBulkInvoiceModal() {
      this.$emit("closeBulkInvoiceModal");
    },
    getJournalEntryCount() {
      const docRef = db.doc(
        "general_journal_entry_counter/LVEzU1bhljJ8ZLLz6pC2"
      );
      docRef.onSnapshot((doc) => {
        this.journal_entry_count = doc.data().journal_entry_count;
      });
    },
  },
};
</script>
