var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-card',[_c('v-data-table',{staticClass:"pt-3",attrs:{"headers":_vm.headers,"items":_vm.invoices.map(function (obj) { return (Object.assign({}, obj,
            {customer_name: obj.customer.customer_name})); }),"search":_vm.search,"loading":_vm.loading,"sort-by":['invoice_number', 'invoice_issue_date'],"dense":"","items-per-page":50,"show-select":_vm.bulkActions,"item-key":"invoice_id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('InvoiceTableFilters',{attrs:{"search":_vm.search,"bulkActions":_vm.bulkActions,"bulkMenuItems":_vm.bulkMenuItems},on:{"changeSearch":function($event){_vm.search = $event},"changeBulkActions":function($event){_vm.bulkActions = !_vm.bulkActions},"changeBulkInvoices":function($event){return _vm.changeBulkInvoices($event)}}})]},proxy:true},{key:"item.invoice_total",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatAsCurrency("R", item.invoice_total))+" ")]}},{key:"item.sales_order_ref",fn:function(ref){
          var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.pushRouteData(item)}}},[_vm._v(_vm._s(item.sales_order_ref))])]}},{key:"item.invoice_id",fn:function(props){return [_c('v-menu',{attrs:{"top":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"black","right":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.menu_items),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',{on:{"click":function($event){$event.stopPropagation();return item.action(props.item)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(_vm._s(item.icon))]),_vm._v(_vm._s(item.title))],1)],1)}),1)],1),_c('InvoicePaymentPreviewModal',{ref:props.item.invoice_id,attrs:{"paymentPreviewDialog":_vm.paymentPreviewDialog,"value":props.item,"type":'invoice'}})]}}]),model:{value:(_vm.selectedInvoices),callback:function ($$v) {_vm.selectedInvoices=$$v},expression:"selectedInvoices"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }