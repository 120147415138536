<template>
  <v-row>
    <v-col>
      <v-card>
        <v-data-table
          v-model="selected_orders"
          :loading="loading"
          :headers="headers"
          :items="filteredItems"
          :search="search"
          :sort-by="['order_date', 'order_number']"
          :show-select="bulkActions"
          item-key="order_id"
          dense
          :items-per-page="70"
          class="pt-3"
        >
          <!--Filters-->
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <!--Order status-->
                <v-col md="3" sm="3">
                  <v-select
                    v-model="order_status"
                    label="Order Status"
                    :items="[
                      { text: 'Open', value: 'Confirmed' },
                      { text: 'Completed', value: 'Completed' },
                    ]"
                    clearable
                    append-icon="mdi-menu-down"
                    dense
                    outlined
                  ></v-select>
                </v-col>
                <!--Search Bar-->
                <v-col md="3" sm="3">
                  <v-text-field
                    v-model="search"
                    label="Search"
                    clearable
                    append-icon="mdi-magnify"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <!--Bulk Actions Btn-->
                <v-col md="3" sm="3">
                  <InvoicesBulkActionsBtn
                    :bulkActions="bulkActions"
                    :bulkMenuItems="bulkMenuItems"
                    @changeBulkActions="changeBulkActions($event)"
                    @changeBulkInvoices="changeBulkInvoices($event)"
                  />
                </v-col>
              </v-row>
            </v-container>
          </template>
          <!--Order #-->
          <template v-slot:item.order_number="{ item }">
            <router-link
              :to="{
                name: 'view-order',
                params: { order_id: item.order_id },
              }"
              target="blank"
              style="text-decoration: none"
              >{{ item.order_number }}</router-link
            >
          </template>
          <!--Customer Name-->
          <template v-slot:item.customer="{ item }">
            {{ item.customer.customer_name }}
          </template>
          <!--Order Value-->
          <template v-slot:item.order_value="{ item }">
            {{ formatAsCurrency("R", item.order_value) }}
          </template>
          <!--Actions-->
          <template v-slot:item.order_id="props">
            <v-btn
              text
              color="#3dcb9a"
              small
              @click.stop="$set(newInvoiceDialog, props.item.order_id, true)"
            >
              <v-icon small left>mdi-plus</v-icon>
              Create Invoice</v-btn
            >
            <v-dialog
              v-model="newInvoiceDialog[props.item.order_id]"
              scrollable
              max-width="950"
              :key="props.item.newInvoiceDialog"
            >
              <NewInvoiceModal :value="props.item" :key="componentKey" />
            </v-dialog>
          </template>
        </v-data-table>
        <v-dialog v-model="bulkInvoiceModal" max-width="600">
          <InvoiceBulkModal
            v-if="bulkInvoiceModal"
            :selected_orders="selected_orders"
            @closeBulkInvoiceModal="closeBulkInvoiceModal($event)"
          />
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import db from "../../../../components/firebaseInit";
import { formatAsCurrency } from "../../../../composables/external";
export default {
  name: "OrdersToInvoiceTable",
  components: {
    NewInvoiceModal: () =>
      import(
        /* webpackChunkName: "invoices" */ "../Modals/NewInvoiceModal.vue"
      ),
    InvoicesBulkActionsBtn: () =>
      import(
        /* webpackChunkName: "invoices" */ "../Buttons/InvoicesBulkActionsBtn.vue"
      ),
    InvoiceBulkModal: () =>
      import(
        /* webpackChunkName: "invoices" */ "../Modals/InvoiceBulkModal.vue"
      ),
  },
  data() {
    return {
      loading: false,
      newInvoiceDialog: {},
      sales_orders: [],
      search: "",
      order_status: "Completed",
      bulkActions: false,
      componentKey: null,
      selected_orders: [],
      bulkInvoiceModal: null,
      bulkMenuItems: [
        {
          name: "Bulk Create Invoices",
          icon: "mdi-file-multiple-outline",
          action: () => this.changeBulkInvoices(true),
        },
      ],
      headers: [
        {
          text: "Order #",
          value: "order_number",
          align: "start",
        },
        { text: "Customer Name", value: "customer" },
        { text: "Date", value: "order_date" },
        { text: "Total", value: "order_value", align: "right" },
        { text: "Actions", value: "order_id", align: "right" },
        { text: "", value: "customer_name", align: " d-none" },
      ],
    };
  },
  computed: {
    filteredOrders() {
      return this.sales_orders
        .filter((item) => item.order_status !== "Cancelled")
        .map((obj) => ({ ...obj, customer_name: obj.customer.customer_name }));
    },
    filteredItems() {
      return this.filteredOrders.filter((i) => {
        return this.order_status === "" || i.order_status === this.order_status;
      });
    },
  },
  created() {
    this.getSalesOrders();
  },
  methods: {
    formatAsCurrency,
    getSalesOrders() {
      this.loading = true;
      const salesDocRef = db
        .collection("sales_orders")
        .where("invoice_id", "==", null)
        .where("order_status", "!=", "Quote");
      salesDocRef.onSnapshot((querySnapshot) => {
        this.sales_orders = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          this.sales_orders.push(data);
        });
        this.loading = false;
      });
    },
    changeBulkActions() {
      this.bulkActions = !this.bulkActions;
    },
    changeBulkInvoices(value) {
      this.bulkInvoiceModal = value;
    },
    closeBulkInvoiceModal() {
      this.bulkInvoiceModal = false;
      this.selected_orders = [];
    },
    updateInvoiceStatus() {
      const salesIDArray = this.sales_orders.map((item) => {
        return item.order_id;
      });
      // Break sales orders into chunks so we can update batches above
      // the limit imposed by firestore writes
      let chunks = [],
        i = 0,
        n = salesIDArray.length;
      while (i < n) {
        chunks.push(salesIDArray.slice(i, (i += 400)));
      }
      chunks.forEach((chunk) => {
        const batch = db.batch();
        chunk.forEach((doc) => {
          const docRef = db.collection("sales_orders").doc(doc);
          batch.update(docRef, { invoice_id: null });
        });
        batch.commit();
      });
    },
  },
};
</script>