<template>
  <v-row>
    <v-col>
      <v-card>
        <v-data-table
          v-model="selectedInvoices"
          :headers="headers"
          :items="
            invoices.map((obj) => ({
              ...obj,
              customer_name: obj.customer.customer_name,
            }))
          "
          :search="search"
          :loading="loading"
          :sort-by="['invoice_number', 'invoice_issue_date']"
          dense
          :items-per-page="50"
          class="pt-3"
          :show-select="bulkActions"
          item-key="invoice_id"
        >
          <!--Filters-->
          <template v-slot:top>
            <InvoiceTableFilters
              :search="search"
              @changeSearch="search = $event"
              :bulkActions="bulkActions"
              :bulkMenuItems="bulkMenuItems"
              @changeBulkActions="bulkActions = !bulkActions"
              @changeBulkInvoices="changeBulkInvoices($event)"
            />
          </template>
          <!--Format Invoice Total-->
          <template v-slot:item.invoice_total="{ item }">
            {{ formatAsCurrency("R", item.invoice_total) }}
          </template>
          <!--Format Sales Order Number-->
          <template v-slot:item.sales_order_ref="{ item }">
            <a @click="pushRouteData(item)">{{ item.sales_order_ref }}</a>
          </template>
          <!--Format Actions Menu-->
          <template v-slot:item.invoice_id="props">
            <!--Menu-->
            <v-menu top left>
              <template v-slot:activator="{ on, attrs }">
                <!--Menu Button-->
                <v-btn icon color="black" right v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <!--Menu List-->
              <v-list>
                <v-list-item v-for="(item, index) in menu_items" :key="index">
                  <v-list-item-title @click.stop="item.action(props.item)"
                    ><v-icon small left>{{ item.icon }}</v-icon
                    >{{ item.title }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
            <!--Paymnet Preview Modal-->
            <!--
                The "ref" is used to trigger a firebase query that populates
                payment data in each modal
                The "ref" is dynamic so that each invoices payments can be accessed
              -->
            <InvoicePaymentPreviewModal
              :ref="props.item.invoice_id"
              :paymentPreviewDialog="paymentPreviewDialog"
              :value="props.item"
              :type="'invoice'"
            />
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import db from "../../../../components/firebaseInit";
import { formatAsCurrency } from "../../../../composables/external";
import {
  generateInvoicePDF,
  mergeInvoicePDFs,
} from "../../data/external_InvoicePDFTemplate";
import mixin_CompanyProfile from "../../../../globalActions/mixin_CompanyProfile";
export default {
  name: "PaidInFullInvoiceTable",
  mixins: [mixin_CompanyProfile],
  components: {
    InvoicePaymentPreviewModal: () =>
      import(
        /* webpackChunkName: "accounting" */ "../../components/Modals/InvoicePaymentPreviewModal.vue"
      ),
    InvoiceTableFilters: () => import("./InvoiceTableFilters.vue"),
  },
  data() {
    return {
      search: "",
      loading: false,
      invoices: [],
      customer_payments: [],
      loadingPayments: false,
      selectedInvoices: [],
      paymentPreviewDialog: {},
      VATDate: null,
      isVattable: null,
      bulkActions: false,
      bulkMenuItems: [
        {
          name: "Merge & Export as PDF",
          icon: "mdi-file-download-outline",
          action: () => this.mergeInvoicePDFs(this.selectedInvoices),
        },
      ],
      headers: [
        {
          text: "Invoice #",
          value: "invoice_number",
          align: "start",
        },
        { text: "Sales Order #", value: "sales_order_ref" },
        { text: "Customer", value: "customer_name" },
        { text: "Date Issued", value: "invoice_issue_date" },
        { text: "Date Due", value: "invoice_due_date" },
        { text: "Total", value: "invoice_total", align: "right" },
        { text: "Actions", value: "invoice_id", align: "right" },
      ],
      menu_items: [
        {
          title: "Download PDF",
          icon: "mdi-download",
          action: (invoice) => this.generateInvoicePDF(invoice),
        },
        {
          title: "View Payments",
          icon: "mdi-eye",
          action: (invoice) => {
            this.$set(this.paymentPreviewDialog, invoice.invoice_id, true);
            //  Used to retreive payments from database and load them in
            //  The modal child component
            this.$refs[invoice.invoice_id].getCustomerPayments();
          },
        },
      ],
    };
  },
  created() {
    this.getInvoices();
    this.getVATdata();
  },
  updated() {
    this.getInvoices();
  },
  methods: {
    formatAsCurrency,
    generateInvoicePDF,
    mergeInvoicePDFs,
    getVATdata() {
      db.collection("company_profile")
        .doc("NVmvKiV1F7os4LCRljSC")
        .get()
        .then((snapshot) => {
          this.isVattable = snapshot.data().company_is_vattable;
          this.VATDate = snapshot.data().VAT_start_date;
        });
    },
    getInvoices() {
      this.loading = true;
      const docRef = db
        .collection("invoices")
        .where("invoice_amount_due", "==", 0);
      docRef.onSnapshot((querySnapshot) => {
        this.invoices = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          this.invoices.push(data);
        });
        this.loading = false;
      });
    },
    pushRouteData(item) {
      const routeData = this.$router.resolve({
        path: `view-order/${item.sales_order_id}`,
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>